import styled from "styled-components";
import { StyledHeading } from "components/core/Typography/Heading/index.style";
import { media, Row } from "styled-bootstrap-grid";
import Heading from "components/core/Typography/Heading";
import { Title } from "components/core/Typography/Title";
import { PageContainer, UnderlinedLink } from "styles/global.style";

const HeaderContainer = styled.div<{ backgroundUrl?: string }>`
  padding-bottom: 64px;
  padding-top: 96px;
  margin-bottom: 32px;
  background: url(${(props) => props.backgroundUrl});
  text-align: center;
  color: ${(props) => props.theme.titleColor};

  ${PageContainer} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ${media.sm`
    padding-top: 140px;
    margin-bottom: 96px;
  `}
`;

const HeaderPayoff = styled(Title)`
  font-size: 1.8em;
  line-height: 1.3em;
  margin-top: 0.5em;
  color: ${(props) => props.theme.titleColor};
  max-width: 900px;

  ${media.sm`
    font-size: 3.5em;
  `}
`;

const HeaderSubtitle = styled(Heading)`
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.baseColor};
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;

  ${StyledHeading} {
    margin-top: 2em;
    font-size: 3.5em;
    line-height: 1.2em;
    color: ${(props) => props.theme.titleColor};
  }
`;

const Content = styled.div`
  padding: 32px 0;

  p {
    font-size: 16px;
    line-height: 28px;
  }

  a {
    font-size: 16px;
    line-height: 28px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.titleColor};
  }

  ${media.sm`
    padding: 32px;
  `};
`;

const ChallengeRow = styled(Row)`
  padding: 0 32px 32px;
  margin: 32px 0 0 0;

  background: #bee8ff33;
  border-radius: ${(props) => props.theme.borderRadius};

  ${media.sm`
    margin: 0 32px;
  `};
`;

const MetadataBox = styled.div``;

const MetadataTitle = styled.span`
  display: block;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2;
  color: ${(props) => props.theme.titleColor};
  margin-top: 1.5em;
  margin-bottom: 0.3em;
`;

const MetadataContent = styled.div``;

const MetadataInnerContent = styled.span`
  display: block;
  font-size: 16px;

  a {
    font-size: 16px;
  }
`;

const OtherSuccessStoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 96px;

  ${media.sm`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `}
`;

const OtherSuccessStory = styled(Title)`
  margin: 0;
`;

const LogoContainer = styled.div`
  width: 80%;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 0.3em;
`;

const SeeMoreLink = styled(UnderlinedLink)`
  margin-right: 0;
  margin-top: 8px;

  svg {
    margin-left: 8px;
    stroke: ${(props) => props.theme.accentColor};
  }

  ${media.sm`
    margin-right: 32px;
    margin-top: 0;
  `}
`;

export {
  HeaderContainer,
  HeaderPayoff,
  HeaderSubtitle,
  HeadingContainer,
  MetadataBox,
  MetadataTitle,
  MetadataContent,
  MetadataInnerContent,
  OtherSuccessStoryContainer,
  Content,
  ChallengeRow,
  LogoContainer,
  OtherSuccessStory,
  SeeMoreLink,
};
